// Variables
@import "variables";

// Colors
$primary-color: #0fcbaf;
$black-color: #0e0801;
$white-color: #fbf2e6;

// Rest
@import "frontend";
